<template>
  <div class="dashboard-container">
    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
      <el-col :span="24" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
            >添加推荐PDF</el-button>
        </el-col>
      </el-row>
    </div>
      <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form
        ref="perForm"
        :model="formData"
        :rules="rules"
        label-width="200px"
      >
        <el-form-item label="标题名称" prop="title">
          <el-input
            v-model="formData.title"
            placeholder="请输入标题名称"
            style="width: 50%"
          />
        </el-form-item>
      
        <el-form-item label="logo" prop="logoId">
          <el-upload
            class="avatar-uploader"
            action="http://up-z2.qiniup.com"
            :show-file-list="false"
            accept="image/jpeg,image/gif,image/png"
            :on-success="handleAvatarSuccess"
            :data="postData"
            :before-upload="beforeAvatarUpload"
          >
            <div v-if="imageUrl" class="avatar">
              <img :src="imageUrl" width="50%" />
            </div>

            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
     
        <el-form-item label="PDF文档" prop="pdfId">
          <el-select
            v-model="formData.pdfId"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.title"
              :value="item.pdfId"
            >
            </el-option>
          </el-select>
        </el-form-item>
     
        

       
        <el-form-item label="排序" prop="sort" v-if="formData.recId">
          <el-input v-model="formData.sort" style="width: 30%" />
        </el-form-item>
      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="6">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
       <div class="tab">
      <el-table
        :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
        :data="list"
        border
        style="width: 100%"
        ref="list"
        class="emp_table"
        v-if="list"
      >
      <el-table-column align="center" prop="logoId" label="logo" width="200">
          <template slot-scope="scope">
            <div class="permitImg-img">
              <el-image
                style="width: 35%"
                :src="'http://img.c-fol.cn/' + scope.row.logoId"
                :preview-src-list="[
                  'http://img.c-fol.cn/' + scope.row.logoId,
                ]"
              />
            </div>
          </template>
        </el-table-column>
              <el-table-column
          align="center"
          prop="title"
          label="标题名称"
          :show-overflow-tooltip="true"
        />
        
         
        <el-table-column
          align="center"
          prop="createTime"
          label="创建时间"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createTime | getLocalTime }}
          </template>
        </el-table-column>
            <el-table-column align="center" label="操作" width="251px">
          <template slot-scope="scope">
          
            <el-button
              type="text"
              size="small"
              @click="modifyRecPDF(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="small"
              @click="delRecPDF(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
     
      </el-table>
      <!-- 分页 -->
     <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.page"
      :limit.sync="queryParams.pageSize"
      @pagination="getRecPDFList"
    />
    </div>
  </div>
</template>


<script>
import {
  modifyRecPDF,
  addRecPDF,
  getUploadToken,
  delRecPDF,
  getPDFInfoList,
 getRecPDFList
} from "../../request/http";
export default {
  data() {
    return {
      title:'',
        showDialog: false, //显示隐藏
        pdfId: "",
      imageUrl: "",
       postData: {
        token: "",
        key: "", //上传的Logo
      },
      options: [],
      list: "", // 用户列表
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10,
      },
       formData: {
         title:'',
         pdfId:'',
        sort: "",
       logoId: "",
      },
      rules: {
        title: [
          { required: true, message: "标题名称不能为空", trigger: "blur" },
        ],
       logoId: [
          { required: true, message: "logo不能为空", trigger: "blur" },
        ],
        pdfId: [
          { required: true, message: "PDF文档不能为空", trigger: "blur" },
        ],
       sort: [
          { required: true, message: "排序不能为空", trigger: "blur" },
        ]
      },
    };
  },
  created() {
     this.getRecPDFList()
     this.getList()
  },
  methods: {
    //获取pdf列表
       async getList() {
       let { data } = await getPDFInfoList();
      this.options = data;
    },
    //选择pdf列表
      selectChange(value) {
      this.formData.pdfId = value;
      console.log(this.formData.pdfId);
    },
     // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://img.c-fol.cn/" + res.key;
      this.formData.logoId = res.key;
    },
       
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data == "" || data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
      this.postData.token = data.token;
      this.postData.key = data.fid;
    },

     // 打开添加的窗口
    addSchoolInfo() {
      // this.imageUrl=null
      this.title = "添加推荐PDF信息";
      this.showDialog = true;
    },

    // 打开编辑类别的窗口
    modifyRecPDF(val) {
      console.log(val);
      this.title = "编辑推荐PDF信息";
      this.imageUrl = "http://img.c-fol.cn/" + val.logoId;
      this.formData = {
       recId: val.recId,
       title: val.title,
        pdfId: val.pdfId,
        logoId:val.logoId,
        sort: val.sort,
      };
      
      this.showDialog = true;
    },
    
    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(() => {
          if (this.formData.recId) {
            this.formData.sort=Number(this.formData.sort)
            return modifyRecPDF(this.formData);
          } else {
            return addRecPDF(this.formData);
          }
        })
        .then(() => {
          //  提示消息
          if (this.formData.recId) {
            this.$message.success("编辑成功");
          } else {
            this.$message.success("新增成功");
          }
          
          this.getRecPDFList();
          this.showDialog = false;
        });
    },

    //弹窗取消函数
    btnCancel() {
      this.imageUrl = "";
      this.formData = {};
      this.$refs.perForm.resetFields();
      this.showDialog = false;
    },
    //删除
    async delRecPDF(row) {
      try {
        await this.$confirm("确定要删除吗");
        await delRecPDF({recId: row.recId });
        const totalPage = Math.ceil(
          (this.total - 1) / this.queryParams.pageSize
        );
        this.queryParams.page =
          this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
        this.queryParams.page =
          this.queryParams.page < 1 ? 1 : this.queryParams.page;
        this.getRecPDFList();

        this.$message.success("删除成功");
      } catch (error) {
        console.log(error);
      }
    },
    
      // 获取用户列表
    async getRecPDFList() {
      const { data, count } = await getRecPDFList(this.queryParams)
      this.list = data
      this.total = count
    },
  },
};
</script>

<style>
</style>